<template>
  <div>
    <div v-if="!isOnlyMobile" class="position-relative d-none d-lg-block">
      <UIBanner
        :classLoading="'placeholders-banner-game'"
        :keyData="'w_article'"
        :isShowCaption="false"
        :classImage="'img-common'"
      />
      <div class="bannerText">Liên hệ</div>
    </div>
    <div class="container py-lg-0 py-3">
      <div class="boxContact bg-white p-3 color-grey">
        <div class="row">
          <div class="col-md-5 col-lg-4 col-12">
            <p class="font-20 mb-1 mt-md-3 mt-0">Hỗ trợ khách hàng</p>
            <!-- <p>Hotline: <a class="color-grey" href="tel:0902905788">0902905788</a></p> -->
            <p>Giờ làm việc: 8:00 - 17:00</p>
            <p>Tất cả các ngày bao gồm cả Lễ Tết</p>
            <p>Email hỗ trợ: {{ emailSupport }}</p>
            <div class="boxContactSocialLink">
              <div class="d-flex mt-2 mt-lg-4">
                <!-- <a href="viber://chat?number=0902905788" class="d-inline-block pointer mr-1"
                  ><img width="40" height="40" src="../../assets/img/icon/icon_viber.svg"
                /></a>
                <a href="https://zalo.me/0902905788" target="_blank" class="d-inline-block pointer mx-1"
                  ><img width="40" height="40" src="../../assets/img/icon/icon_zalo.svg"
                /></a> -->
                <a href="https://m.me/111694644907922" target="_blank" class="boxContactSocialLinkMessenger pointer"
                  ><img class="h-auto mr-2" width="25" src="../../assets/img/icon/icon_fbmess-white.svg" />
                  Yêu cầu hỗ trợ
                </a>
              </div>
            </div>
          </div>
          <div class="col-md-5 col-lg-6 col-12">
            <p class="font-20 mb-1 mt-md-3 mt-3">Văn phòng chính</p>
            <p>
              CÔNG TY CỔ PHẦN GIẢI TRÍ SỐ WELUCK
            </p>
            <p>
              Địa chỉ: 104 Mai Thị Lựu , Phường Đa Kao, Quận 1, Thành phố Hồ Chí Minh
            </p>
          </div>
        </div>
        <!-- <div class="boxContactSocialLink d-block d-md-none">
          <div class="d-flex mt-4">
            <a href="https://m.me/111694644907922" target="_blank" class="boxContactSocialLinkMessenger pointer"
              ><img class="h-auto mr-2" width="25" src="../../assets/img/icon/icon_fbmess-white.svg" />
              Yêu cầu hỗ trợ
            </a>
          </div>
        </div> -->
        <div class="boxContactBackgroundImage d-none d-md-block">
          <img src="../../assets/img/icon/contact_img.svg" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import UIBanner from '@/components/UI/Banner/UIBanner';
import { EMAIL_SUPPORT } from '../../common/global';

export default {
  components: {
    UIBanner
  },
  data() {
    return {
      content: undefined,
      title: '',
      emailSupport: EMAIL_SUPPORT
    };
  },
  created() {
    // this.getArticleBySlug();
  },
  computed: {
    isOnlyMobile() {
      return this.$store.getters.getterIsOnlyMobile;
    }
  },
  methods: {
    // redirectLink() {
    //   var urlLink = 'http://www.facebook.com/OwenDunnigan';
    //   switch (this.getMobileOperatingSystem()) {
    //     case 'Android':
    //       urlLink = 'http://m.me/thuong.le.9803150';
    //       break;
    //     case 'iOS':
    //       urlLink = 'http://m.me/thuong.le.9803150';
    //       break;
    //     default:
    //       break;
    //   }
    //   window.location.href = urlLink;
    // },
    // getMobileOperatingSystem() {
    //   var userAgent = navigator.userAgent || navigator.vendor || window.opera;
    //   if (userAgent.match(/iPad/i) || userAgent.match(/iPhone/i) || userAgent.match(/iPod/i)) {
    //     return 'iOS';
    //   } else if (userAgent.match(/Android/i)) {
    //     return 'Android';
    //   } else {
    //     return 'unknown';
    //   }
    // }
    // getArticleBySlug() {
    //   let slug = this.$route.path.split('/')[2];
    //   this.$store
    //     .dispatch('getArticleBySlug', slug)
    //     .then((resp) => {
    //       const { content, title } = resp;
    //       this.$emit('updateTitleMobile', title);
    //       this.title = title;
    //       this.content = content;
    //     })
    //     .catch(() => {
    //       this.$router.push({ name: 'notFound' });
    //     });
    // }
  }
};
</script>
<style lang="scss" scoped>
.boxContactSocialLinkMessenger {
  background-color: #2196f3;
  color: #fff;
  font-weight: 600;
  padding: 0.475rem 0.75rem;
  border-radius: 4px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  background-position: center;
  transition: background 0.8s;

  &:hover {
    color: #ffffff;
    background: #2196f3 radial-gradient(circle, transparent 1%, #2196f3 1%) center/15000%;
  }
  &:active {
    background-color: #2597f4;
    background-size: 100%;
    transition: background 0s;
  }
}
.boxContactBackgroundImage {
  position: absolute;
  bottom: 0;
  right: 0;
}
.boxContact {
  height: 397px;
  border: 5px;
  margin-bottom: 3rem;
  position: relative;
}

@media (min-width: map-get($grid-breakpoints, 'lg')) and (max-width: 1196px) {
  .boxContactBackgroundImage {
    img {
      width: 90%;
      float: right;
    }
  }
}

@media (min-width: map-get($grid-breakpoints, 'md')) and (max-width: map-get($grid-breakpoints, 'fix-lg')) {
  .boxContactBackgroundImage {
    img {
      width: 80%;
      float: right;
    }
  }
}

@media (max-width: map-get($grid-breakpoints, 'md')) {
  .boxContact {
    height: auto;
  }
}

@media (max-width: map-get($grid-breakpoints, 'sm')) {
  .boxContactSocialLinkMessenger {
    width: 100%;
  }
}
</style>
